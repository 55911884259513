import React, { Component } from 'react'

class ColoredLetter extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  render () {
    let styleObj = {
        color: this.props.color
    }

    return (
        <span style={styleObj}>{this.props.text}</span>
    )
  }
}

export default ColoredLetter

const initialState = {}
import React, { Component } from 'react'
import './Testimonial.css'

class Testimonial extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  render () {
    const {
        name,
        text,
        direction,
        link,
        color
    } = this.props;

    return (
        <div className='full-width'>
            <div style={{backgroundColor: color}} className={`testimonial-div testimonial-${direction}`}>
                <div className='testimonial-text'>
                    "{text}"
                </div>
                {link != null && <div className='testimonial-name'>
                    - <a href={link} target="_blank">{name}</a>
                </div>}
                {link == null && <div className='testimonial-name'>- {name}</div>}
            </div>
        </div>
    )
  }
}

export default Testimonial

const initialState = {
  
}
import React, { Component } from 'react'
import './HomeContainer.css'
import AboutContainer from '../AboutContainer/AboutContainer'
import DatingContainer from '../DatingContainer/DatingContainer'
import TestimonialsContainer from '../TestimonialsContainer/TestimonialsContainer'
import StoriesContainer from '../StoriesContainer/StoriesContainer'
import NavContainer from '../NavContainer/NavContainer'
import DougHeader from '../../components/DougHeader/DougHeader'
import { pages } from '../../constants/constants'

class HomeContainer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  setPage (page) {
    console.log('hit')
    this.setState({ selectedPage: page })
  }

  render () {
    return (
      <div className='home-container'>
          <DougHeader />
          <div className='content-section'>
            {this.state.selectedPage === pages.ABOUT && <AboutContainer />}
            {this.state.selectedPage === pages.DATING && <DatingContainer />}
            {this.state.selectedPage === pages.TESTIMONIALS && <TestimonialsContainer />}
            {this.state.selectedPage === pages.STORIES && <StoriesContainer />}
          </div>
          <div className='nav-section'>
            <NavContainer
              selectedPage={this.state.selectedPage}
              setPage={(page) => this.setPage(page)}
            />
          </div>
      </div>
    )
  }
}

export default HomeContainer

const initialState = {
  selectedPage: 'about'
}
import React, { Component } from 'react'
import './DougHeader.css'
import doughead from '../../images/doug-head.png'

class DougHeader extends Component {
  constructor (props) {
    super(props)

    this.state = { 
      ...initialState
    }
  }

  render () {
    return (
      <div className='doug-header'>
        <img src={doughead} alt="Our Doug and Savior" />
      </div>
    )
  }
}

export default DougHeader

const initialState = {}
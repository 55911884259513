import React, { Component } from 'react'
import Story from '../../components/Story/Story'
import './StoriesContainer.css'

class StoriesContainer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  render () {
    return (
      <div className='stories-container'>
          <div className='story-header'>
            <h2>Stories</h2>
          </div>
          {this.state.stories.map((t, i) => <Story key={`story_${i}`} {...t} />)}
      </div>
    )
  }
}

export default StoriesContainer

const initialState = {
  stories: [
    {
      text: '<p>Doug is a hellspawn.</p><p>An actual hellspawn.</p><p>Please, send help before Doug finds this</p>',
      color: '#43ABC9'
    },
    {
      text: `<p>My wife recently lost a loved one.</p><p>As she laid there sobbing, Doug had never sang a happier song.</p>`,
      color: '#A3B86C'
    },
  ]
}
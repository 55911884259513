import React, { Component } from 'react'
import './AboutContainer.css'
import Introduction from '../../components/Introduction/Introduction'
import Facts from '../../components/Facts/Facts'

class AboutContainer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  render () {
    return (
      <div className='about-container'>
          <Introduction />
          <Facts />
      </div>
    )
  }
}

export default AboutContainer

const initialState = {
  
}
import React, { Component } from 'react'
import './DatingImage.css'
import dougFull from '../../images/doug-full-1.jpg'

class DatingImage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  render () {
    return (
      <div className='dating-image'>
          <img src={dougFull} alt='Doug almighty' />
      </div>
    )
  }
}

export default DatingImage

const initialState = {
  
}
import './App.css';
import HomeContainer from './containers/HomeContainer/HomeContainer'
import 'antd/dist/antd.css'

function App() {
  return (
    <HomeContainer />
  );
}

export default App;
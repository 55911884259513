import React, { Component } from 'react'
import './DatingDescription.css'

class DatingDescription extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  render () {
    return (
      <div className='dating-description'>
          <h3><u>Hey baby, you want <span className='italics'>PERFECTION</span>?</u></h3>
          <div className='dating-info-div'>
            <p>
              Ever heard of a Chad? Scholars say it was actually derived from Doug.
            </p>
            <p>
              95 grams of pure testosterone. What are you sporting? 3 grams?
            </p>
            <p>
              Alpha male, sigma male, beta male... All Doug, baby. Need Doug say more?
            </p>
            <p>
              Doug will absolutely dominate and scare other potential mates away with Doug's displays of aggression.
            </p>
            <p>
              <span style={{fontWeight: 'bold'}}>Requirements:</span> Literally anyone who will give Doug love
            </p>
            <p>
              <span style={{fontWeight: 'bold'}}>Dealbreakers:</span> At this point, nothing
            </p>
            <p>
              <span style={{fontWeight: 'bold'}}>Please Note:</span> Doug will make a terrible father
            </p>
          </div>
      </div>
    )
  }
}

export default DatingDescription

const initialState = {
  
}
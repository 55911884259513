import React, { Component } from 'react'
import NavItem from '../../components/NavItem/NavItem'
import './NavContainer.css'
import { pages } from '../../constants/constants'

class NavContainer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  render () {
    return (
      <div className='nav-container'>
        <div className='nav-elements'>
          <NavItem
            page={pages.ABOUT}
            text={'About'}
            selectedPage={this.props.selectedPage}
            setPage={this.props.setPage}
          />
          <NavItem
            page={pages.DATING}
            text={'Dating Profile'}
            selectedPage={this.props.selectedPage}
            setPage={this.props.setPage}
          />
          <NavItem
            page={pages.TESTIMONIALS}
            text={'Testimonials'}
            selectedPage={this.props.selectedPage}
            setPage={this.props.setPage}
          />
          <NavItem
            page={pages.STORIES}
            text={'Stories'}
            selectedPage={this.props.selectedPage}
            setPage={this.props.setPage}
          />
        </div>
      </div>
    )
  }
}

export default NavContainer

const initialState = {
  
}
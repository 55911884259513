import React, { Component } from 'react'
import Testimonial from '../../components/Testimonial/Testimonial'
import './TestimonialsContainer.css'

class TestimonialsContainer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  render () {
    return (
      <div className='testimonials-container'>
          <div className='testimonial-header'>
            <h2>Testimonials</h2>
          </div>
          {this.state.testimonials.map((t, i) => <Testimonial key={`testimonial_${i}`} {...t} />)}
      </div>
    )
  }
}

export default TestimonialsContainer

const initialState = {
  testimonials: [
    {
      name: 'B.L., Coworker',
      text: `I've heard him on calls before`,
      direction: 'left',
      color: '#43ABC9'
    },
    {
      name: 'Pastor B.',
      text: `Oh? How's she doing?`,
      direction: 'right',
      color: '#A3B86C'
    },
    {
      name: 'An Actual, Certified Veterinarian Who is Licensed to Practice on Animals',
      text: `He's a little chunky, but it's okay.`,
      direction: 'left',
      color: '#CD594A'
    },
    {
      name: 'The Author of This Site',
      text: `An actual hellspawn who lives in my house`,
      direction: 'right',
      color: '#43ABC9'
    },
    {
      name: 'Former Neighbor',
      text: `You have a bird???`,
      direction: 'left',
      color: '#A3B86C'
    },
    {
      name: '[Redacted]',
      text: `Absolutely a stunning specimen. Magnificent. Doug would date Doug.`,
      direction: 'right',
      link: `http://www.doug.pet`,
      color: '#CD594A'
    }
  ]
}
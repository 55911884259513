import React, { Component } from 'react'
import './Introduction.css'

class Introduction extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  render () {
    return (
      <div className='introduction'>
          <div className='introduction-header'>
            Welcome to Doug
          </div>
          <div className="introduction-section-1">
            If you're like us, you have questions in life.
          </div>
          <div className="introduction-section-2">
            Doug doesn't have answers, but he is Doug.
          </div>
          <div className="introduction-section-3">
            "But," I hear your sinful voice ask, "What is Doug?"
          </div>
          <div className="introduction-section-4">
            "Whom'st," is Doug, sinner.
          </div>
      </div>
    )
  }
}

export default Introduction

const initialState = {}
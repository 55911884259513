import React, { Component } from 'react'
import ColoredLetter from '../ColoredLetter/ColoredLetter'
import './Facts.css'

class Facts extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  render () {
    return (
      <div className='facts-section'>
          <div className='facts-header'>
            <span className='facts-header-span'>
              <ColoredLetter color='red' text='F' />
              <ColoredLetter color='blue' text='A' />
              <ColoredLetter color='green' text='C' />
              <ColoredLetter color='purple' text='T' />
              <ColoredLetter color='orange' text='S' />
              <span>&nbsp;</span>
              <ColoredLetter color='#de1fc0' text='A' />
              <ColoredLetter color='#1FDE3D' text='B' />
              <ColoredLetter color='red' text='O' />
              <ColoredLetter color='blue' text='U' />
              <ColoredLetter color='green' text='T' />
              <span>&nbsp;</span>
              <ColoredLetter color='purple' text='D' />
              <ColoredLetter color='orange' text='O' />
              <ColoredLetter color='#de1fc0' text='U' />
              <ColoredLetter color='#1FDE3D' text='G' />
            </span>
          </div>
          <div className='facts-list-section'>
              <ul className='facts-list'>
                  <li>Age: {new Date().getFullYear() - 2010}, but who is counting?</li>
                  <li>Pronouns: Doug/Doug's <span className='vet-sig'>Author's Note: Any references to 'he/him' will result in the author's ears being decimated</span></li>
                  <li>Height: Well enough</li>
                  <li>Weight: 'A little chunky, but it's okay' - <span className='vet-sig'>Certified Vet&trade;</span></li>
                  <li>Colors: Magnificent </li>
                  <li>Eye Color: The darkest depths of Hades (but also brown)</li>
                  <li>Temperament: Unavailable</li>
                  <li>Favorite Music: Whatever you're playing</li>
                  <li>Least Favorite Music: Whatever you're playing</li>
                  <li>Marital Status: Please see Doug's dating profile</li>
                  <li>Hobbies: Screaming, making life miserable for anyone who enters Doug's radius, curating a fine seed collection</li>
                  <li>Approximate Value: <span className='italics'>See &lt;Colors&gt;</span></li>
                  <li>Fears: Literally anything that is not Doug</li>
                  <li>Abilities: Able to detect something Doug does not like from over half a mile away, and alerting those unfortunate enough to care for Doug about this distaste</li>
                  <li>Beak sharpness: "Ouch #$%^ he bit me" - <span className='vet-sig'>A very unfortunate wife</span></li>
                  <li>Hidden Skills: Ear piercings (both physically and audibly)</li>
              </ul>
          </div>
      </div>
    )
  }
}

export default Facts

const initialState = {}
import React, { Component } from 'react'
import DatingDescription from '../../components/DatingDescription/DatingDescription'
import DatingImage from '../../components/DatingImage/DatingImage'
import './DatingContainer.css'

class DatingContainer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  render () {
    return (
      <div className='dating-container'>
          <div className='dating-image-container'>
            <DatingImage />
          </div>
          <div className='dating-description-container'>
            <DatingDescription />
          </div>
      </div>
    )
  }
}

export default DatingContainer

const initialState = {
  
}
import React, { Component } from 'react'
import './NavItem.css'

class NavItem extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  render () {
    let selected = this.props.page === this.props.selectedPage;

    return (
        <div className='nav-item' onClick={() => this.props.setPage(this.props.page)}>
            <div className='nav-table'>
              <span className={`nav-item-span ${selected ? 'selected' : ''}`}>
                  {this.props.text}
              </span>
            </div>
        </div>
    )
  }
}

export default NavItem

const initialState = {}
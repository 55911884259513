import React, { Component } from 'react'
import './Story.css'

class Story extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  render () {
    const {
        text,
        color
    } = this.props;

    return (
        <div className='full-width'>
            <div style={{backgroundColor: color}} className={`story-div`}>
                <div className='story-text' dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        </div>
    )
  }
}

export default Story

const initialState = {
  
}